<script setup
        lang="ts">
import { useEvent, usePageLeave, usePageView }                    from '@reshepe/hooks';
import { useAuthStore }                                           from '@stores/auth.store';
import { useThemeStore }                                          from '@reshepe/stores';
import { useChatStore }                                           from '@stores/chat.store';
import { useScriptTag }                                           from '@vueuse/core';
import { useNProgress }                                           from '@vueuse/integrations/useNProgress';
import { onMounted, ref, inject, defineAsyncComponent, computed } from 'vue';
import { useRoute, useRouter }                                    from 'vue-router';
import { WebVitals }                                              from '@reshepe-web-vitals/vue';
import type { PostHog }                                           from 'posthog-js';
import Toast                                                      from '@reshepe/ui/toast.vue';

const GdprNotice = defineAsyncComponent(() => import('@reshepe/ui/gdpr.vue'));

useThemeStore();
const { isLoading } = useNProgress();
const router = useRouter();
const route = useRoute();
const auth_store = useAuthStore();
const posthog = inject<PostHog | undefined>('posthog');

router.onError((error, to) => {
    if (
        error.message.includes('Failed to fetch dynamically imported module') ||
        error.message.includes('Importing a module script failed')
    ) {
        if (!to?.fullPath) {
            window.location.reload();
        } else {
            window.location.href = to.fullPath;
        }
    }
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.redirect) {
        next(to.meta.redirect);
        if (to.meta.redirect === from.path) {
            isLoading.value = false;
        }
    } else {
        if (from.path === '/auth/login' && to.meta.requires_auth && !auth_store.is_logged_in) {
            next(from.fullPath);
        } else {
            if (to.path !== from.path) {
                isLoading.value = true;
            }

            if (to.meta.redirect) {
                next(to.meta.redirect);
                if (to.meta.redirect === from.path) {
                    isLoading.value = false;
                }
            } else {
                if (to.meta.requires_auth && !auth_store.is_logged_in) {
                    next('/auth/login?r=' + to.fullPath);
                } else if (auth_store.is_logged_in) {
                    if (to.meta.hide_for_logged_in) {
                        next('/');
                    } else {
                        next();
                    }
                } else {
                    next();
                }
            }
        }
    }
});

router.afterEach((to, from, failure) => {
    if (!failure) {
        usePageLeave(from.path);
        usePageView(to.path);
    }
});

window.addEventListener('beforeunload', () => {
    posthog?.capture('$pageleave', {
        path: router.currentRoute.value.path,
    });
});

window.addEventListener('vite:preloadError', (e: Event): void => {
    e.preventDefault();

    useEvent('vite:preloadError');

    window.location.reload();
});

const loaded = ref<boolean>(false);
const dev = ref<boolean>(import.meta.env.MODE === 'development');
const preview = ref<boolean>(import.meta.env.VITE_VERCEL_ENV === 'preview');

const console_messages = (): void => {
    console.info(
        '01110100 01101000 01101001 01110011 00100000 01100111 01110101 01111001 00100000 01100110 01110101 01100011 01101011 01110011',
    );

    console.info(`%c
⠄⠄⠄⠄⠄⠄⠄⢀⣠⣶⣾⣿⣶⣦⣤⣀⠄⢀⣀⣤⣤⣤⣤⣄⠄⠄⠄⠄⠄⠄
⠄⠄⠄⠄⠄⢀⣴⣿⣿⣿⡿⠿⠿⠿⠿⢿⣷⡹⣿⣿⣿⣿⣿⣿⣷⠄⠄⠄⠄⠄
⠄⠄⠄⠄⠄⣾⣿⣿⣿⣯⣵⣾⣿⣿⡶⠦⠭⢁⠩⢭⣭⣵⣶⣶⡬⣄⣀⡀⠄⠄
⠄⠄⠄⡀⠘⠻⣿⣿⣿⣿⡿⠟⠩⠶⠚⠻⠟⠳⢶⣮⢫⣥⠶⠒⠒⠒⠒⠆⠐⠒
⠄⢠⣾⢇⣿⣿⣶⣦⢠⠰⡕⢤⠆⠄⠰⢠⢠⠄⠰⢠⠠⠄⡀⠄⢊⢯⠄⡅⠂⠄
⢠⣿⣿⣿⣿⣿⣿⣿⣏⠘⢼⠬⠆⠄⢘⠨⢐⠄⢘⠈⣼⡄⠄⠄⡢⡲⠄⠂⠠⠄
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣷⣥⣀⡁⠄⠘⠘⠘⢀⣠⣾⣿⢿⣦⣁⠙⠃⠄⠃⠐⣀
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣋⣵⣾⣿⣿⣿⣿⣦⣀⣶⣾⣿⣿⡉⠉⠉
⣿⣿⣿⣿⣿⣿⣿⠟⣫⣥⣬⣭⣛⠿⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡆⠄
⣿⣿⣿⣿⣿⣿⣿⠸⣿⣏⣙⠿⣿⣿⣶⣦⣍⣙⠿⠿⠿⠿⠿⠿⠿⠿⣛⣩⣶⠄
⣛⣛⣛⠿⠿⣿⣿⣿⣮⣙⠿⢿⣶⣶⣭⣭⣛⣛⣛⣛⠛⠛⠻⣛⣛⣛⣛⣋⠁⢀
⣿⣿⣿⣿⣿⣶⣬⢙⡻⠿⠿⣷⣤⣝⣛⣛⣛⣛⣛⣛⣛⣛⠛⠛⣛⣛⠛⣡⣴⣿
⣛⣛⠛⠛⠛⣛⡑⡿⢻⢻⠲⢆⢹⣿⣿⣿⣿⣿⣿⠿⠿⠟⡴⢻⢋⠻⣟⠈⠿⠿
⣿⡿⡿⣿⢷⢤⠄⡔⡘⣃⢃⢰⡦⡤⡤⢤⢤⢤⠒⠞⠳⢸⠃⡆⢸⠄⠟⠸⠛⢿
⡟⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠁⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⢸
`, 'color: green;');

    if (preview.value) {
        console.log(import.meta.env);
    }
};

const chat_store = useChatStore();

const {
    load:   chat_load,
    unload: chat_unload,
} = useScriptTag(
    '//eu.fw-cdn.com/12781688/764985.js',
    () => {
        window.fwcrm.on('widget:loaded', () => {
            chat_store.is_loaded = true;

            window.fcWidget.user.get((data) => {
                if (data?.data && data.data.restoreId) {
                    if (auth_store.account && !auth_store.account.chat_id) {
                        auth_store.chat_id_save(data.data.restoreId);
                    }
                }
            });

            window.fcWidget.on('user:created', (data) => {
                if (data?.data && data.data.restoreId) {
                    if (auth_store.account && !auth_store.account.chat_id) {
                        auth_store.chat_id_save(data.data.restoreId);
                    }
                }
            });
        });
    },
    {
        manual: true,
    },
);

onMounted(async () => {
    document.getElementById('app')?.classList.remove('hidden');
    document.getElementById('app')?.classList.add('block');
    document.getElementById('loader')?.remove();

    if (!dev.value) {
        console_messages();
    }

    if (auth_store.is_logged_in) {
        await auth_store.me();
    }

    loaded.value = true;

    window.fcWidgetMessengerConfig = {
        config: {
            headerProperty: {
                hideChatButton: true,
            },
        },
    };

    if (auth_store.account) {
        window.fcWidgetMessengerConfig.externalId = auth_store.account.email;
        window.fcWidgetMessengerConfig.email = auth_store.account.email;
        window.fcWidgetMessengerConfig.meta = {
            cf_customer_id: auth_store.account.id,
        };

        if (auth_store.account.chat_id) {
            window.fcWidgetMessengerConfig.restoreId = auth_store.account.chat_id;
        }
    }

    await chat_load();
});

onMounted(async () => {
    chat_unload();
});

const has_route_watch_meta = computed<boolean>(() => !!(route.name && route.meta.watch && typeof route.meta.watch === 'string' && route.params[route.meta.watch]));

const route_key = computed<string>(() => has_route_watch_meta.value
    ? `${route.name!.toString()}-${route.params[route.meta.watch as string]?.toString()}`
    : '');
</script>

<template>
    <router-view v-slot="{ Component }"
                 :key="route_key">
        <component :is="Component" />
    </router-view>
    <GdprNotice v-if="loaded" />
    <Toast />
    <WebVitals api-key="pk_7acec75f-22ea-4d3c-8cc1-5eb15f62682a"
               :development="dev || preview"
               report-soft-navigation />
</template>
