import { useAuthStore }  from '@stores/auth.store';
import { useToastStore } from '@reshepe/stores';
import { type Axios }    from '@reshepe/types';
import { setupCache }    from 'axios-cache-interceptor';
import axios             from 'axios';

const headers = {
    'Content-Type': 'application/json',
};

const instance = axios.create({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    baseURL: import.meta.env.VITE_APP_API_URL,
    headers,
});

const api = setupCache(instance) as Axios;

api.interceptors.request.use(
    (config) => {
        if (config?.headers) {
            const auth = useAuthStore();
            if (auth.session) {
                config.headers.Authorization = `Bearer ${auth.session}`;
            }
        }

        return config;
    },
    async (error) => Promise.reject(error),
);

const showError = (toast: ReturnType<typeof useToastStore>, error?: string | string[]): void => {
    if (error) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        Array.isArray(error)
            ? error.forEach((message: string) => toast.error(message))
            : toast.error(error);
    } else {
        toast.error('something went wrong, try again later');
    }
};

let refreshPromise: null | Promise<void> = null;

export const refreshToken = (auth: ReturnType<typeof useAuthStore>): Promise<void> => {
    if (!refreshPromise) {
        refreshPromise = auth.me().then(async () => {
            if (auth.session) {
                api.defaults.headers.common.Authorization = `Bearer ${auth.session}`;
            }

            refreshPromise = null;
        });
    }

    return refreshPromise;
};

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalConfig = error.config;
        originalConfig._attempt = originalConfig._attempt ? originalConfig._attempt + 1 : 1;
        const toast = useToastStore();

        if (error.response) {
            const auth = useAuthStore();

            if (!originalConfig._retry) {
                switch (error.response.status) {
                    case 401:
                        if (originalConfig.url !== '/v1/auth/me' && !auth.is_logging_out && auth.is_logged_in) {
                            await refreshToken(auth);

                            originalConfig._retry = true;

                            return api(originalConfig);
                        } else {
                            await auth.expired();
                        }

                        break;
                    case 408:
                    case 425:
                    case 429:
                    case 500:
                    case 502:
                    case 503:
                    case 504:
                        originalConfig._retry = originalConfig._attempt > 1;

                        return api(originalConfig);
                    default:
                        showError(toast, error.response.data?.response);

                        break;
                }
            } else {
                switch (error.response.status) {
                    case 400:
                        showError(toast, error.response.data?.response);

                        break;
                    case 401:
                        await auth.expired();

                        return Promise.reject(401);
                    case 429:
                        toast.error('too many requests, try again later');

                        break;
                    default:
                        toast.error('something went wrong, try again later');
                }
            }
        } else {
            if (originalConfig._attempt < 3) {
                await new Promise((resolve) => setTimeout(resolve, originalConfig._attempt * 300));

                return api(originalConfig);
            } else {
                toast.error('something went wrong, try again later');
            }
        }

        return Promise.reject(error);
    },
);

export {
    api,
};
